// import " style={{borderRightWidth: '.5px'}}./styles.css";
// import Html from " style={{borderRightWidth: '.5px'}}react-pdf-html";
// import ExportPdfComponent from " style={{borderRightWidth: '.5px'}}./ExportPdfComponent";
// import " style={{borderRightWidth: '.5px'}}../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Document, Image, Page, Text, } from "@react-pdf/renderer";
import React, { useState } from "react";
// import { Document, Page } from " style={{borderRightWidth: '.5px'}}react-pdf";
// import { PDFdiver } from " style={{borderRightWidth: '.5px'}}@react-pdf/renderer";
// import MyDocument from " style={{borderRightWidth: '.5px'}}./MyDocument";
// import html2canvas from " style={{borderRightWidth: '.5px'}}html2canvas";
// import jsPDF from " style={{borderRightWidth: '.5px'}}jspdf";
import logo from "../assets/logo1.jpeg";

interface Props {
    data: any;
}

const PdfExport = React.forwardRef((props: Props,ref: any) => {
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }: any) {
  //   setNumPages(numPages);
  // }
  const heading = ['Sl no.','TimeStamp','LA(s)','LC(s)','LZ(s)','LA(eqt)','LC(eqt)','LZ(eqt)','LA(peakt)','LC(peakt)','LZ(peakt)']
  console.log(props?.data)
//   const printDocument=()=> {
//     const input: any = document.getElementById("divToPrint");
//     html2canvas(input).then((canvas: any) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, " style={{borderRightWidth: '.5px'}}JPEG", 0, 0, 0, 0);
//       // pdf.output('dataurlnewwindow');
//       pdf.save("download.pdf");
//     });
//   };

  return (
    <div style={{fontFamily: 'Inter',margin: '20px'}} ref={ref}>
      <div style={{padding: '10px',margin: '10px', display: 'flex', justifyContent: 'center'}}>
          <img src={logo} alt='' style={{width: '50%'}} />
      </div>
      <h2 style={{textAlign: 'center'}} className="font-semibold mb-8">
        REAL TIME NOISE MONITORING NETWORK REPORT
      </h2>
      <div className="flex justify-center mb-8">

      <div style={{display: 'grid', gridTemplateColumns: '2fr 5fr', borderWidth: '.5px',borderColor: 'black',fontFamily: 'Inter',borderStyle: 'solid',width: '70%', justifySelf: 'center'}}>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm font-semibold">
              STATION NAME
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center',}} className="px-1 text-sm ">
            {props?.data[0]?.location}
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm font-semibold">
              LATITUDE
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm ">
            {props?.data[0]?.lat}
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm font-semibold">
              LONGITUDE
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm ">
            {props?.data[0]?.long}
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm font-semibold">
              STATION ID
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm ">
            {props?.data[0]?.location_id}
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm font-semibold">
              START DATE TIME
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm ">
          {props?.data[props?.data?.length-1]?.timestamp}
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm font-semibold">
              END DATE TIME
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm ">
            {props?.data[0]?.timestamp}
          </div>
          <div style={{borderWidth: '.5px' ,borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="px-1 text-sm font-semibold">
              PARAMTERS
          </div>
          <div style={{borderWidth: '.5px',borderColor: 'black',borderStyle: 'solid', textAlign: 'center'}} className="flex justify-around w-fit whitespace-nowrap">
              {heading.map((item:any)=> {
                return(
                    (item !== ('Sl no.') && item !== ('TimeStamp')) && <div className="text-xs flex">{`${item}`}
                      <div className="bg-black" style={{height: '20px',width: '1px',margin: '.5px'}}></div>
                    </div>
                  )}
                )}
          </div>
      </div>
      </div>
      <div>
        <table className="border my-2 border-collapse" width={'100%'}>
          <thead className="flex" style={{backgroundColor: '#4373c3'}}>
            {heading.map((head:any)=>{
              return head !== 'TimeStamp' ? <th className="w-1/12 text-center  text-xs" style={{borderRightWidth: '.5px'}}>{head}</th>:
              <th className="w-2/12 text-center text-xs">{head}</th>
            }
            )}
          </thead>
          
            {props?.data?.map((item:any)=>{
              return(
                <tr className="flex justify-around tablerow items-center border" >
                  <td className="w-1/12 text-center text-xs" style={{borderRightWidth: '.5px'}}>{item?.id}</td>
                  <td className="w-2/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.timestamp}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.las}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.lcs}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.lzs}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.laeqt}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.lceqt}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.lzeqt}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.lapeakt}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.lcpeakt}</td>
                  <td className="w-1/12 text-center text-xs " style={{borderRightWidth: '.5px'}}>{item?.lzpeakt}</td>
                </tr>
              )
            })}
        </table>
      </div>
    </div>
  )
});

export default PdfExport;
