import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export interface getDeviceListReturnItemType {
  id: number;
  dev_id: string;
  location: string;
  box_id: string;
  street: string;
  zone: string;
  createdAt: string;
  updatedAt: string;
  lat: string;
  long: string;
  is_enabled_int: boolean;
  district: string | null;
  is_enabled_ext: boolean;
  laeqt: string;
}

export interface getDeviceListReturnType {
  status: string;
  data: getDeviceListReturnItemType[];
}

export const getDeviceList = async (): Promise<
  AxiosResponse<getDeviceListReturnType>
> => {
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "https://noisedata.wbpcb.gov.in:2443/anms/sens/get_device_list",
  };
  const response: AxiosResponse<getDeviceListReturnType> = await axios(config);
  return response;
};
