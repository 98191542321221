import React, { useEffect, useMemo, useRef, useState } from "react";
import { enableRipple } from "@syncfusion/ej2-base";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ColumnDirective,
  ColumnsDirective,
  Grid,
  GridComponent,
  ToolbarItems,
  ExcelExport,
  Inject,
  Toolbar,
  PdfExport,
  Filter,
  Sort,
  FilterSettingsModel,
  excelHeaderQueryCellInfo,
} from "@syncfusion/ej2-react-grids";
// import Select from "react-select";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import moment from "moment";
import { IBaseComponent } from "./types";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../store";
import Select from "react-select";
import { wbpcb_base64 } from "../util/logo_base64";
enableRipple(true);

export interface IHistoryDatagridComponent extends IBaseComponent {
  rowIndexValue: React.MutableRefObject<number>;
  onPdfExport: any;
}
export const HistoryDatagrid: React.FC<IHistoryDatagridComponent> = ({
  Devices,
  SelectedDevice,
  setSelectedDevice,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  Data,
  TotalData,
  rowIndexValue,
  onPdfExport,
}) => {
  const gridref = useRef<GridComponent | null>(null);
  const toolbar: ToolbarItems[] = [
    "ExcelExport",
    "PdfExport",
    // "CsvExport",
    "Search",
    // "Print",
  ];
  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };
  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (gridref.current?.excelExportModule as any).sheet.images === null ||
      (gridref.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (gridref.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      image: wbpcb_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 3,
    };
    (gridref.current?.excelExportModule as any).sheet.images.push(excelImage);
  };
  const toolbarClick = (args: ClickEventArgs | undefined) => {
    if (gridref.current === null || !args) return;
    switch (args.item.text) {
      case "Excel Export":
        rowIndexValue.current = 0;
        gridref.current.excelExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.xlsx`,
          header: {
            headerRows: 7,
            rows: [
              {
                cells: [
                  {
                    colSpan: 12,
                    value: "WBPCB",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 18,
                      hAlign: "Right",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: "West Bengal Pollution Control Board",
                    style: {
                      backColor: "#cde6f7",
                      fontColor: "#000000",
                      fontSize: 16,
                      hAlign: "Right",
                      bold: true,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `Device: ${
                      DropDownListItems.find(
                        (i) => i.value === SelectedDevice?.dev_id
                      )?.label
                    }`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value:
                      "Parameter: LA(s) |	LC(s) |	LZ(s) |	LA(eqt) |	LC(eqt) |	LZ(eqt) |	LA(peakt) |	LC(peakt) |	LZ(peakt)",
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `From ${moment(StartDate).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )} To ${moment(EndDate).format("DD/MM/YYYY hh:mm:ss")}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: `Downloaded on ${moment().format(
                      "DD-MM-YYYY hh:mm:ss A"
                    )}`,
                    style: {
                      fontSize: 12,
                    },
                  },
                ],
              },
              {
                cells: [
                  {
                    colSpan: 12,
                    value: DropDownListItems.find(
                      (i) => i.value === SelectedDevice?.dev_id
                    )?.label,
                    style: {
                      backColor: "#204FAC",
                      fontColor: "#ffffff",
                      fontSize: 13,
                      hAlign: "Center",
                    },
                  },
                ],
              },
            ],
          },
        });
        break;
      case "PDF Export":
        rowIndexValue.current = 0;
        onPdfExport(Data);
        //@ts-ignore
        // return <PdfExport data={Data}/>;
        // gridRef.current.pdfExport({
        //   fileName: `${SelectedDevice?.location},${
        //     SelectedDevice?.street
        //   }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
        //     "ll"
        //   )}.pdf`,
        //   theme: {
        //     header: {
        //       fontSize: 12,
        //       bold: true,
        //     },
        //     record: {
        //       fontSize: 11,
        //     },
        //   },
        //   pageOrientation: "Landscape",
        //   // header: {
        //   //   contents: [
        //   //     {
        //   //       type: "Text",
        //   //       value: `Device Location: ${SelectedDevice?.location}, ${
        //   //         SelectedDevice?.street
        //   //       } | Device Id: ${SelectedDevice?.dev_id} | Start Date: ${moment(
        //   //         StartDate
        //   //       ).format("ll")} | EndDate: ${moment(EndDate).format("ll")}`,
        //   //     },
        //   //   ],
        //   // },
        //   // footer: {
        //   //   contents: [
        //   //     {
        //   //       type: "PageNumber",
        //   //       pageNumberType: "Arabic",
        //   //     },
        //   //   ],
        //   // },
        // });

        break;
      case "CSV Export":
        rowIndexValue.current = 0;
        gridref.current.csvExport({
          fileName: `${SelectedDevice?.location},${
            SelectedDevice?.street
          }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
            "ll"
          )}.csv`,
        });
        break;
    }
  };
  const [district, setDistrict] = useState<any[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "North 24 Pgs.",
    value: "North 24 Pgs.",
  });
  const [DropDownListItems, setDropDownListItems] = useState<any[]>([]);

  // const DropDownListItems: any[] = useMemo(
  //   () =>
  //     Devices.map((d) => {
  //       const item: any = {
  //         label: `${d.location}, ${d.street}`,
  //         value: d.dev_id,
  //       };
  //       return item;
  //     }),
  //   [Devices]
  // );
  const { state } = useSelector((state: RootState) => state.user);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      // This line disable the blue border

      border: state.isFocused ? "1px solid #acacac" : "1px solid #acacac",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        boxShadow: state.isFocused ? 0 : 0,
      },
    }),
  };

  useEffect(() => {
    const filterdData = Devices?.filter(
      (k) => k.district === selectedDistrict?.value
    );

    const deviceOptionArray = filterdData?.map((item) => ({
      value: item.dev_id,
      label: `${item.location}, ${item.street}`,
    }));

    setDropDownListItems(deviceOptionArray);
  }, [selectedDistrict, Devices]);

  useEffect(() => {
    const districtOptionArray = Devices?.map((item) => ({
      value: item.district,
      label: item.district,
    }));

    setDistrict(
      //@ts-ignore
      [...new Set(districtOptionArray.map((a) => JSON.stringify(a)))].map((a) =>
        JSON.parse(a)
      )
    );
  }, [Devices]);
  // if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <div className="flex flex-col justify-start items-center w-full">
      <div className="flex flex-row flex-wrap justify-start items-center gap-3 w-full m-3">
        <Select
          className="basic-single  text-black mx-2"
          classNamePrefix="select"
          styles={customStyles}
          isSearchable
          name="color"
          placeholder={selectedDistrict}
          value={selectedDistrict}
          onChange={(e: any) => {
            setSelectedDistrict(e);
          }}
          options={district}
        />
        <Select
          className="basic-single text-black mx-2"
          classNamePrefix="select"
          styles={customStyles}
          isSearchable
          name="color"
          placeholder={
            DropDownListItems.find((i) => i.value === SelectedDevice?.dev_id)
              ?.label
          }
          onChange={(e: any) => {
            setSelectedDevice(Devices.find((d) => d.dev_id === e.value));
          }}
          options={DropDownListItems}
        />
        <div className="mx-2">
          <div className="flex flex-row gap-3">
            <DatePickerComponent
              id="datepicker"
              value={StartDate}
              placeholder="Enter Start Date"
              onChange={(e: any) => {
                setStartDate(new Date(e.value));
              }}
            />
            <DatePickerComponent
              id="datepicker"
              placeholder="Enter End date"
              value={EndDate}
              min={StartDate}
              onChange={(e: any) => {
                setEndDate(new Date(e.value));
              }}
            />
          </div>
        </div>
        <div className="whitespace-nowrap mx-2">Showing {TotalData} rows</div>
      </div>
      <div
        style={{ height: "75vh", width: "99%" }}
        className="w-screen overflow-y-scroll mb-10 border-2 border-gray-600 ml-5 mr-5"
      >
        <GridComponent
          dataSource={Data}
          ref={(g) => (gridref.current = g)}
          toolbar={toolbar}
          filterSettings={filterOptions}
          toolbarClick={toolbarClick}
          allowExcelExport
          allowPdfExport
          allowFiltering
          allowSorting
          showColumnMenu={false}
          enableAltRow
          dataBound={(e) => {
            rowIndexValue.current = 0;
          }}
          beforeDataBound={(e) => {
            rowIndexValue.current = 0;
          }}
          // @ts-ignore
          excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective field="id" headerText="Sl no." width={100} />
            <ColumnDirective
              field="location"
              headerText="Location"
              width={170}
            />
            <ColumnDirective
              field="timestamp"
              textAlign="Left"
              headerText="Timestamp"
              width={170}
            />
            <ColumnDirective
              field="las"
              textAlign="Left"
              headerText="LA(s)"
              width={170}
            />
            <ColumnDirective
              field="lcs"
              textAlign="Left"
              headerText="LC(s)"
              width={170}
            />
            <ColumnDirective
              field="lzs"
              textAlign="Left"
              headerText="LZ(s)"
              width={170}
            />
            <ColumnDirective
              field="laeqt"
              textAlign="Left"
              headerText="LA(eqt)"
              width={170}
            />
            <ColumnDirective
              field="lceqt"
              textAlign="Left"
              headerText="LC(eqt)"
              width={170}
            />
            <ColumnDirective
              field="lzeqt"
              textAlign="Left"
              headerText="LZ(eqt)"
              width={170}
            />
            <ColumnDirective
              field="lapeakt"
              textAlign="Left"
              headerText="LA(peakt)"
              width={170}
            />
            <ColumnDirective
              field="lcpeakt"
              textAlign="Left"
              headerText="LC(peakt)"
              width={170}
            />
            <ColumnDirective
              field="lzpeakt"
              textAlign="Left"
              headerText="LZ(peakt)"
              width={170}
            />
          </ColumnsDirective>
          <Inject services={[Toolbar, ExcelExport, PdfExport, Filter, Sort]} />
        </GridComponent>
      </div>
    </div>
  );
};
