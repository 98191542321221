import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GraphReport, HistoryDatagrid } from "./pages";
import { Header } from "./components";
import {
  getDeviceList,
  getDeviceListReturnItemType,
  getDeviceListReturnType,
  getHistoricalData,
  getHistoricalDataReturnItemType,
  getHistoricalDataReturnType,
} from "./api";
import { IBaseComponent } from "./pages/types";
import { AxiosResponse } from "axios";
import moment from "moment";
import MapView from "./pages/mapview";
import { SignIn } from "./pages/signin";
import PdfExport from "./components/PdfExport";
// import html2canvas from "html2canvas";
// import jsPDF, { Html2CanvasOptions } from "jspdf";
import { saveAs } from "file-saver";
import ReactPDF, {
  Document,
  Page,
  pdf,
  PDFDownloadLink,
  Text,
  View,
} from "@react-pdf/renderer";
//@ts-ignore
import Pdf from "react-to-pdf";
import { useReactToPrint } from "react-to-print";
import { VscLoading } from "react-icons/vsc";

export const App: React.FC = () => {
  const [Devices, setDevices] = useState<getDeviceListReturnItemType[]>([]);
  const [SelectedDevice, setSelectedDevice] =
    useState<getDeviceListReturnItemType>();
  const [StartDate, setStartDate] = useState<Date>(new Date());
  const [animate, setanimate] = useState("Overlay");
  const [EndDate, setEndDate] = useState<Date>(new Date());
  const [Data, setData] = useState<getHistoricalDataReturnItemType[]>([]);
  const [TotalData, setTotalData] = useState(0);
  // const [SelectedDataParameter, setSelectedDataParameter] = useState<string>();
  const [download, setDownload] = useState<any>(false);
  const [SelectedDataParameter, setSelectedDataParameter] = useState<string>();
  const [loading, setLoading] = useState(false);
  const loadDevices = useCallback(async () => {
    // enqueueSnackbar(`Fetching Device List.`, {
    //   variant: "info",
    // });
    setLoading(true);
    try {
      const res: AxiosResponse<getDeviceListReturnType> = await getDeviceList();
      console.log({ res });
      if (typeof res?.data === "undefined") {
        // enqueueSnackbar(`Failed to fetch device list.`, {
        //   variant: "error",
        // });
        setDevices([]);
      } else {
        // enqueueSnackbar(
        //   `Device list fetched. ${res.data.data.length} devices fetched.`,
        //   {
        //     variant: "success",
        //   }
        // );
        const response_data: getDeviceListReturnType = res.data;
        setDevices(response_data.data.filter((d) => d.is_enabled_ext));
        setSelectedDevice(response_data.data[response_data.data.length - 1]);
      }
    } catch (error) {
      setDevices([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    loadDevices();
  }, []);
  let rowIndexValue = useRef<number>(0);
  useEffect(() => {
    const getHistoryData = async () => {
      // enqueueSnackbar(`Fetching Historic ANMS data.`, {
      //   variant: "info",
      // });
      const res: AxiosResponse<getHistoricalDataReturnType> =
        await getHistoricalData({
          location_id: SelectedDevice?.id || "",
          start_time: moment(StartDate).format("YYYY-MM-DD") + " 00:00:00",
          end_time: moment(EndDate).format("YYYY-MM-DD") + " 23:59:59",
        });
      // console.log({ res });
      if (typeof res?.data === "undefined") {
        // enqueueSnackbar(`Failed to fetch latest Historic ANMS data.`, {
        //   variant: "error",
        // });
        setData([]);
        rowIndexValue.current = 0;
        setTotalData(0);
      } else {
        // enqueueSnackbar(
        //   `Latest Historic ANMS data fetched. ${res.data.total} rows fetched.`,
        //   {
        //     variant: "success",
        //   }
        // );
        const response_data: getHistoricalDataReturnType = res.data;

        setData(
          response_data.data.map((i, index) => ({
            ...i,
            timestamp: moment(i.timestamp).format("lll"),
            id: index + 1,
            location: SelectedDevice ? SelectedDevice.location : "N/A",
            location_id: SelectedDevice ? SelectedDevice.box_id : "N/A",
          }))
        );
        setSelectedDataParameter("laeqt");
        setTotalData(response_data.total);
      }
    };
    rowIndexValue.current = 0;
    console.log("Resetting rowIndexValue");
    getHistoryData();
  }, [SelectedDevice, StartDate, EndDate]);
  const PropsToPass: IBaseComponent = {
    Data,
    setData,
    Devices,
    setDevices,
    StartDate,
    setStartDate,
    EndDate,
    setEndDate,
    SelectedDevice,
    setSelectedDevice,
    TotalData,
    setTotalData,
  };

  if (typeof window !== "undefined") {
    window.addEventListener("keydown", (e: any) => {
      if (e.keyCode === 32 || e.keyCode === 13) {
        setanimate("animating");
      }
    });
  }
  const pdfHandler = (args: any) => {
    console.log(args);
    // const input: any = document.getElementById("divToPrint");
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/svg");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData,'png',20, 10, 160, 140);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save("download.pdf");
    // });
    // ReactPDF.render(<PdfExport data={args}/>,`${__dirname}/example.pdf`)
    // const blob = await pdf(
    //   <Document >
    //     <Page style={{height: '50%'}}>
    //       {/* <PdfExport
    //           data={args}
    //       /> */}
    //       {/* <View>
    //         <p style={{ textAlign: "center" }}>jdskncskjnkjds</p>
    //       </View> */}
    //     </Page>
    //   </Document>
    // ).toBlob();
    // saveAs(blob, 'document');
    handlePrint();
    setDownload(true);
  };
  const downloadRef: any = createRef();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    //@ts-ignore
    content: () => componentRef.current,
    //onAfterPrint: () => setPrint(!print)
  });

  return (
    <Router>
      <div className="flex-1 flex flex-col w-full items-stretch min-h-screen">
        {/* {download && <Pdf targetRef={downloadRef} filename="document">
          {({toPdf}:any)=> toPdf}
        </Pdf>} */}
        <div id="divToPrint" className="hidden">
          <PdfExport data={Data} ref={componentRef} />
        </div>
        {/* {download && <PDFDownloadLink document={<PdfExport data={Data}/>} fileName="document" style={{height: '50vh'}}>
          {({blob,url,loading,error})=> 
            loading ? 'Loading document...' : 'Download now!'
          }
        </PDFDownloadLink>} */}
        <Header />

        {loading ? (
          <div className="flex flex-col gap-4 items-center justify-center my-auto ">
            <VscLoading className="animate-spin" size="100px" />
            <p className="font-semibold  font-serif">Loading...</p>
          </div>
        ) : (
          <div className="flex flex-1 flex-col box-border">
            <Switch>
              <Route exact path="/signin">
                <SignIn {...PropsToPass} />
              </Route>
              <Route exact path="/graph">
                <GraphReport
                  {...PropsToPass}
                  setSelectedDataParam={setSelectedDataParameter}
                  SelectedDataParam={SelectedDataParameter}
                />
              </Route>
              <Route exact path="/map">
                <MapView {...PropsToPass} />
              </Route>
              <Route exact path="/history">
                <HistoryDatagrid
                  onPdfExport={pdfHandler}
                  {...PropsToPass}
                  rowIndexValue={rowIndexValue}
                />
              </Route>
              <Route exact path="/">
                <MapView {...PropsToPass} />
              </Route>
            </Switch>
          </div>
        )}
      </div>
    </Router>
  );
};
