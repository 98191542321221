import React from "react";
import { getDeviceListReturnItemType } from "../api";
import {
  getNoiseLevelColorCode,
  NoiseLevelZoneType,
} from "../util/noise_color_getter";
import ReactTooltip from "react-tooltip";
export interface MapMarker_props {
  lat: number;
  lng: number;
  text: string;
  data: getDeviceListReturnItemType;
  selected: boolean;
  onClick: () => void;
}
export const MapMarker: React.FC<MapMarker_props> = ({
  selected,
  data,
  onClick,
}) => {
  const color = getNoiseLevelColorCode(
    parseFloat(data.laeqt),
    data.zone as NoiseLevelZoneType
  );

  return (
    <>
      <div data-tip={data.location}
        className={`rounded-full text-black bg-blue-300 border-gray-800 ${
          selected ? "h-16 w-16 border-4 z-50" : "h-10 w-10 border-2 z-0"
        } font-semibold flex flex-row justify-center items-center cursor-pointer shadow-md
      `}
        onClick={onClick}
      >
        <p className="">{data.laeqt}</p>
        <span
          className="animate-ping absolute inline-flex h-7 w-7 rounded-full opacity-40"
          style={{ backgroundColor: "#6B7280" }}
        ></span>
      </div>
      <ReactTooltip />
    </>
  );
};
