import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";
import { MockSignIn } from "../api/mocks/signin";
import { UserInputCredentials, login, updateLogStatus } from "../slices/user.slice";
import { RootState } from "../store";
import GoogleMapReact from "google-map-react";
import { useAppDispatch } from "../util/hooks";
import styles from './signin.module.css';
import { IBaseComponent } from "./types";
import { MapMarker } from "../components/mapmarker";
export const SignIn: React.FC<IBaseComponent> = ({ Devices }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserInputCredentials>();
  const { state } = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  console.log(watch("userId"));
  const onSubmit: SubmitHandler<UserInputCredentials> = async (data) => {
    await MockSignIn(data)
      .then((r) => {
        dispatch(login(r));
      })
      .catch((e) => {
        console.log(e);
        dispatch(updateLogStatus("guest"));
      });
  };
  if (state === "loggedIn") return <Redirect to="/map" />;
  return (
    <div className="flex flex-col flex-1 justify-center items-center">
      <GoogleMapReact
        style={{ flex: 1, width: "100%" }}
        bootstrapURLKeys={{ key: "AIzaSyCxB4NOyF5Du4Ih88qXosmZHq4HVCAG_yk" }}
        defaultCenter={{ lat: 22.860674, lng: 87.353625 }}
        defaultZoom={10}
        resetBoundsOnResize
      >
        {Devices.map((d) => (
          <MapMarker
            lat={parseFloat(d.lat)}
            lng={parseFloat(d.long)}
            key={d.id}
            data={d}
            text={d.location}
            selected={false}
            onClick={() => {}}
          />
        ))}
      </GoogleMapReact>
      <form
        className="flex absolute flex-col justify-center items-stretch p-8 border-2 border-gray-200 rounded-lg bg-blend-overlay bg-white bg-opacity-75 gap-4"
        style={{ minWidth: 400 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col justify-start items-start w-full">
          <div className="p-1 text-gray-600 text-lg">User ID</div>
          <input
            type="email"
            id="userId"
            {...register("userId", { required: true })}
            className="rounded-md w-full p-2 text-lg shadow-md"
          />
          <span className="text-xs text-red-500 px-1">
            {!!errors.userId ? errors.userId.message : ""}
          </span>
        </div>
        <div className="flex flex-col justify-start items-start w-full">
          <div className="text-lg p-1 text-gray-600">Password</div>
          <input
            type="password"
            id="userId"
            {...register("password", { required: true })}
            className="rounded-md w-full p-2 text-lg shadow-md"
          />
          <span className="text-xs text-red-500 px-1">
            {!!errors.password ? errors.password.message : ""}
          </span>
        </div>
        <input
          type="submit"
          className={`p-4 bg-gray-300 text-gray-50 rounded-md shadow-md font-semibold font-sans hover:text-gray-200 hover:bg-gray-700 transition-colors duration-500 ${styles.signinbutton}`}
          value="Sign In"
        />
      </form>
    </div>
  );
};
