import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  DropDownButtonComponent,
  ItemModel,
} from "@syncfusion/ej2-react-splitbuttons";
import { enableRipple } from "@syncfusion/ej2-base";
import React, { useMemo, useRef, useState } from "react";
import { getHistoricalDataReturnItemType } from "../api";
import {
  Category,
  ChartComponent,
  DataLabel,
  LineSeries,
  Legend,
  Tooltip,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  DateTimeCategory,
  Export,
} from "@syncfusion/ej2-react-charts";
import { IBaseComponent } from "./types";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { RootState } from "../store";
import { CommandColumnRenderer } from "@syncfusion/ej2-react-grids";
import Select from "react-select";

export interface IGraphReportComponent extends IBaseComponent {
  SelectedDataParam: string | undefined;
  setSelectedDataParam: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}
enableRipple(true);
export const GraphReport: React.FC<IGraphReportComponent> = ({
  Devices,
  SelectedDevice,
  setSelectedDevice,
  StartDate,
  setStartDate,
  EndDate,
  setEndDate,
  Data,
  SelectedDataParam,
  setSelectedDataParam,
  TotalData,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const unused_parameters = {
    id: null,
    dev_id: null,
    timestamp: null,
    rssi: null,
    power_good: null,
    battery: null,
    createdAt: null,
    updatedAt: null,
    lat: null,
    long: null,
    tamper: null,
    solar_good: null,
    temperature: null,
    location: null,
    created_at: null,
  };

  const DeviceDataParameters = useMemo(() => {
    if (Data.length === 0) {
      const DeviceParams: ItemModel[] = [];
      return DeviceParams;
    }
    let item: getHistoricalDataReturnItemType = Data[0];
    return Object.keys(item)
      .map(
        (i) =>
          ({
            text: i,
            id: i,
          } as ItemModel)
      )
      .filter((i) => !(!i?.id || i.id in unused_parameters));
  }, [Data]);

  const params = [
    { text: "LA(s)", id: "las" },
    { text: "LC(s)", id: "lcs" },
    { text: "LZ(s)", id: "lzs" },
    { text: "LA(eqt)", id: "laeqt" },
    { text: "LC(eqt)", id: "lceqt" },
    { text: "LZ(eqt)", id: "lzeqt" },
    { text: "LA(peakt)", id: "lapeakt" },
    { text: "LC(peakt)", id: "lcpeakt" },
    { text: "LZ(peakt)", id: "lzpeakt" },
  ];

  // const DeviceDropDownListItems: any[] = useMemo(
  //   () =>
  //     Devices.map((d) => {
  //       const item: any = {
  //         label: `${d.location}, ${d.street}`,
  //         value: d.dev_id,
  //       };
  //       return item;
  //     }),
  //   [Devices]
  // );
  const DownloadOptions: ItemModel[] = useMemo(
    () =>
      ["JPEG", "PNG", "PDF", "SVG"].map(
        (i) =>
          ({
            text: i,
            id: i,
          } as ItemModel)
      ),
    []
  );
  const chartRef = useRef<ChartComponent | null>(null);
  const screenRef = useRef<HTMLDivElement | null>(null);
  const { state } = useSelector((state: RootState) => state.user);
  const [ParamLabel, setParamLabel] = React.useState<string>("LA(eqt)");
  const [district, setDistrict] = useState<any[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: "North 24 Pgs.",
    value: "North 24 Pgs.",
  });
  const [DropDownListItems, setDropDownListItems] = useState<any[]>([]);

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      // This line disable the blue border

      border: state.isFocused ? "1px solid #acacac" : "1px solid #acacac",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        boxShadow: state.isFocused ? 0 : 0,
      },
    }),
  };

  React.useEffect(() => {
    if (chartRef.current) {
      chartRef.current.title = `ANMS ${
        DropDownListItems.find((i) => i.value === SelectedDevice?.dev_id)?.label
      } ${moment(StartDate).format("MM/DD/YYYY hh:mm:ss a")} To ${moment(
        EndDate
      ).format("MM/DD/YYYY hh:mm:ss a")}`;
      chartRef.current?.refresh();
    }
  }, [SelectedDataParam, SelectedDevice, StartDate, EndDate]);
  React.useEffect(() => {
    const filterdData = Devices?.filter(
      (k) => k.district === selectedDistrict?.value
    );

    const deviceOptionArray = filterdData?.map((item) => ({
      value: item.dev_id,
      label: `${item.location}, ${item.street}`,
    }));

    setDropDownListItems(deviceOptionArray);
  }, [selectedDistrict, Devices]);

  React.useEffect(() => {
    const districtOptionArray = Devices?.map((item) => ({
      value: item.district,
      label: item.district,
    }));

    setDistrict(
      //@ts-ignore
      [...new Set(districtOptionArray.map((a) => JSON.stringify(a)))].map((a) =>
        JSON.parse(a)
      )
    );
  }, [Devices]);

  // if (state !== "loggedIn") return <Redirect to="/signin" />;

  const primaryyaxis = {
    title: ParamLabel,
  };
  return (
    <div
      className="flex flex-1 flex-col justify-start items-center w-full"
      ref={(r) => (screenRef.current = r)}
    >
      <div className="flex flex-row justify-start flex-wrap items-center w-full gap-3 mt-3">
        <Select
          className="basic-single  text-black mx-2"
          classNamePrefix="select"
          styles={customStyles}
          isSearchable
          name="color"
          placeholder={selectedDistrict}
          value={selectedDistrict}
          onChange={(e: any) => {
            setSelectedDistrict(e);
          }}
          options={district}
        />
        <Select
          className="basic-single text-black mx-2"
          classNamePrefix="select"
          styles={customStyles}
          isSearchable
          name="color"
          placeholder={
            DropDownListItems.find((i) => i.value === SelectedDevice?.dev_id)
              ?.label
          }
          onChange={(e: any) => {
            setSelectedDevice(Devices.find((d) => d.dev_id === e.value));
          }}
          options={DropDownListItems}
        />

        <div className="mx-2">
          <div className="flex flex-row gap-3">
            <DatePickerComponent
              id="datepicker"
              value={StartDate}
              placeholder="Enter Start Date"
              onChange={(e: any) => {
                setStartDate(new Date(e.value));
              }}
            />
            <DatePickerComponent
              id="datepicker"
              placeholder="Enter End date"
              value={EndDate}
              min={StartDate}
              onChange={(e: any) => {
                setEndDate(new Date(e.value));
              }}
            />
          </div>
        </div>
        <DropDownButtonComponent
          id="paramsList"
          items={params}
          select={(e: any) => {
            setSelectedDataParam(e.item.properties.id);
            setParamLabel(e.item.text);
          }}
        >
          {typeof SelectedDataParam === "undefined"
            ? "Select Data Parameter"
            : ParamLabel}
        </DropDownButtonComponent>
        <div className="flex flex-1 justify-end items-center whitespace-nowrap">
          Showing {TotalData} points
        </div>
        <div className="mr-2">
          <DropDownButtonComponent
            id="downloadOptions"
            items={DownloadOptions}
            select={(e: any) => {
              console.log("Downloading ", e.item.properties.id);
              if (chartRef.current === null)
                enqueueSnackbar(`Chart is not loaded yet.`, {
                  variant: "error",
                });
              else {
                chartRef.current.exportModule.export(
                  e.item.properties.id,
                  `${SelectedDevice?.location},${
                    SelectedDevice?.street
                  }-${moment(StartDate).format("ll")}-${moment(EndDate).format(
                    "ll"
                  )}`
                );
              }
            }}
          >
            Download
          </DropDownButtonComponent>
        </div>
      </div>
      <div className="flex-1">
        {Data.length > 0 && !!SelectedDataParam && (
          <ChartComponent
            id="graph"
            ref={(c) => (chartRef.current = c)}
            primaryXAxis={{
              title: `Date-Time`,
              valueType: "DateTimeCategory",
              labelFormat: "d/M/y H:m",
              labelRotation: 270,
              isInversed: true,
            }}
            primaryYAxis={primaryyaxis}
            tooltip={{ enable: true }}
            style={{
              marginTop: 20,
            }}
            width={`${window.innerWidth}`}
          >
            <Inject
              services={[
                LineSeries,
                Legend,
                Tooltip,
                DataLabel,
                Category,
                Export,
                DateTimeCategory,
              ]}
            />
            <SeriesCollectionDirective>
              <SeriesDirective
                dataSource={[...Data].reverse()}
                xName="timestamp"
                yName={SelectedDataParam}
                name={`${SelectedDataParam?.toUpperCase()} Data: ${
                  SelectedDevice?.location
                }, ${SelectedDevice?.street} From ${moment(StartDate).format(
                  "ll"
                )} To ${moment(EndDate).format("ll")}`}
                animation={{ enable: true, duration: 1200, delay: 100 }}
                type="Line"
                marker={{ visible: true, width: 5, height: 5 }}
                enableTooltip
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        )}
      </div>
    </div>
  );
};
