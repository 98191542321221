import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type logState = "loggedIn" | "guest" | "loggingIn";

export type UserInputCredentials = {
  userId: string;
  password: string;
};

export interface UserState {
  userId?: string;
  username?: string;
  token?: string;
  state: logState;
  logInTime?: string;
}

export const initialState: UserState = {
  state: "guest",
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (
      state: UserState,
      action: PayloadAction<Omit<Required<UserState>, "state">>
    ) => {
      state.userId = action.payload.userId;
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.logInTime = action.payload.logInTime;
      state.state = "loggedIn";
      return state;
    },
    updateLogStatus: (state: UserState, action: PayloadAction<logState>) => {
      state.state = action.payload;
      return state;
    },
    reset: (state: UserState, action: PayloadAction<void>) => {
      state = initialState;
      return state;
    },
  },
});

export const { login, reset, updateLogStatus } = UserSlice.actions;
export default UserSlice.reducer;
