import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { reset } from "../slices/user.slice";
import { useAppDispatch, useAppSelector } from "../util/hooks";

export const Header = () => {
  const { location } = useHistory();
  const [path, setpath] = useState(location.pathname);
  const dispatch = useAppDispatch();
  const { username, state } = useAppSelector((s) => s.user);
  return (
    <div
      className="bg-blue-500 text-white text-lg px-4 py-2 Header"
      style={{
        backgroundImage:
          "linear-gradient(90deg, rgb(28, 181, 224) 0%, rgb(0, 8, 81) 100%)",
      }}
    >
      <div className="flex flex-row justify-center items-center">
        <img
          src="WBPCB_fulllogo.png"
          alt="WBPCB Logo"
          className="max-w-screen-sm bg-gray-100 Logo"
        />
      </div>
      <div className=" text-white drop-shadow-lg filter rounded text-center p-2 font-sans Title">
        CONTINUOUS NOISE MONITORING SYSTEM
      </div>
      <div className="flex flex-row justify-center items-center gap-2">
        <Link
          to="/map"
          onClick={(e) => setpath("/map")}
          className={`text-white border-2 p-2 font-sans text-xs hover:bg-white hover:text-blue-500 whitespace-nowrap ${
            path === "/map" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Map View
        </Link>
        <Link
          to="/history"
          onClick={(e) => setpath("/history")}
          className={`text-white border-2 p-2 font-sans text-xs hover:bg-white hover:text-blue-500 ${
            path === "/history" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Grid
        </Link>
        <Link
          to="/graph"
          onClick={(e) => setpath("/graph")}
          className={`text-white border-2 p-2 font-sans text-xs hover:bg-white hover:text-blue-500 ${
            path === "/graph" ? "border-b-8" : "text-white bg-transparent"
          }`}
        >
          Charts
        </Link>
      </div>
    </div>
  );
};
