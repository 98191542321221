import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";

export interface getANMSData_Type {
  id: number;
  dev_id: string;
  timestamp: Date;
  las: string;
  lcs: string;
  lzs: string;
  laeqt: string;
  lceqt: string;
  lzeqt: string;
  lapeakt: string;
  lcpeakt: string;
  lzpeakt: string;
  rssi: number;
  power_good: boolean;
  battery: number;
  createdAt: Date;
  updatedAt: Date;
  lat?: number;
  long?: number;
  tamper?: boolean;
  temperature?: number;
  solar_good?: boolean;
}

export interface getANMSData_Returns {
  status: string;
  data: getANMSData_Type;
}

export const getANMSData = async (dev_id: string) => {
  const data = qs.stringify({
    dev_id: dev_id,
  });
  const config: AxiosRequestConfig = {
    method: "post",
    url: "https://noisedata.wbpcb.gov.in:2443/anms/sens/get_latest_data",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };
  const response: AxiosResponse<getANMSData_Returns> = await axios(config);
  return response;
};
